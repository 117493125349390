<template>
  <button v-if="text !== 'null' && text" v-tooltip.top-center="text">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 511.999 511.999"
      style="enable-background:new 0 0 511.999 511.999;"
      xml:space="preserve"
    >
      <g>
        <g>
          <path
            d="M511.999,102.871c0-7.743-3.015-15.022-8.49-20.496l-11.386-11.388c-5.475-5.475-12.755-8.49-20.498-8.49
			c-7.743,0-15.022,3.015-20.498,8.49l-53.653,53.653V67.463c0-5.632-4.567-10.199-10.199-10.199H10.199
			C4.566,57.263,0,61.831,0,67.463v377.074c0,5.632,4.566,10.199,10.199,10.199h377.075c5.632,0,10.199-4.567,10.199-10.199
			l-0.004-215.126l106.04-106.041C508.984,117.895,511.999,110.614,511.999,102.871z M377.075,434.337H20.398V77.663h356.676v67.376
			L190.347,331.766c-1.12,1.12-1.963,2.486-2.464,3.987l-18.98,56.939c-1.222,3.665-0.268,7.706,2.464,10.437
			c1.944,1.944,4.551,2.987,7.214,2.987c1.079,0,2.167-0.171,3.224-0.523l56.939-18.979c1.502-0.501,2.867-1.344,3.987-2.464
			l134.344-134.344V434.337z M230.008,368.024l-35.304,11.767l11.768-35.304l216.563-216.563l23.536,23.536L230.008,368.024z
			 M489.085,108.945l-28.09,28.091l-11.768-11.768L437.46,113.5l28.091-28.091c1.623-1.623,3.779-2.516,6.074-2.516
			c2.295,0,4.451,0.893,6.074,2.515l11.387,11.388c0,0.001,0,0.001,0.001,0.001c1.622,1.623,2.515,3.779,2.515,6.073
			C491.602,105.166,490.708,107.323,489.085,108.945z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M318.125,121.009h-9.724c-5.632,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h9.724
			c5.632,0,10.199-4.567,10.199-10.199C328.325,125.577,323.757,121.009,318.125,121.009z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M264.103,121.009H78.267c-5.633,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h185.837
			c5.632,0,10.199-4.567,10.199-10.199C274.302,125.577,269.735,121.009,264.103,121.009z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M254.379,180.434H78.267c-5.633,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h176.113
			c5.632,0,10.199-4.567,10.199-10.199C264.579,185.001,260.011,180.434,254.379,180.434z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M190.633,239.859H78.267c-5.633,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h112.367
			c5.633,0,10.199-4.567,10.199-10.199C200.833,244.426,196.266,239.859,190.633,239.859z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M142.014,298.202H78.267c-5.633,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h63.746
			c5.633,0,10.199-4.567,10.199-10.199C152.212,302.769,147.647,298.202,142.014,298.202z"
          />
        </g>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: "ebp-note-button",
  props: {
    text: String
  }
};
</script>

<style lang="scss">
button {
  background: transparent;
  border: 0;

  svg {
    width: 16px;

    g {
      stroke: #ccc;
    }
  }
}
</style>
