import Joi from "joi";
import errorMessages from "./errorMessages.json";
import contributors from "./custom/contributors";

export default Joi.object({
  title: Joi.string().max(150),
  authors: Joi.custom(contributors)
})
  .unknown()
  .messages(errorMessages);
