export default function(contributors = [], helper) {
  if (contributors.length) {
    return (
      contributors[0].role === "author" ||
      helper.message("validation-errors.first-contributor-author")
    );
  }

  return helper.message("validation-errors.{#label}-min");
}
