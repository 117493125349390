<template>
  <div class="admin-books">
    <advanced-data-table
      action="books/get"
      :headers="headers"
      :filters.sync="filters"
      ref="table"
      :items="books"
      v-bind="options"
    >
      <template #cell(select)="{ item }">
        <input type="checkbox" v-model="selectedItems" :value="item.id" />
      </template>
      <!-- Book title -->
      <template #cell(title)="{ item }">
        <div class="title" v-if="item.title.length >= 1">
          <span class="details">
            <router-link
              :to="{
                name: 'AdminViewSingleBook',
                params: { id: item.id }
              }"
              class="text-dark"
              >{{ item.title }}</router-link
            >
          </span>
          <i
            @click="openChanges(item)"
            class="bx bxs-info-circle"
            v-tooltip.top-center="$t('unacknowledged-changes')"
            v-if="item.is_changed && isLive(item)"
          ></i>
        </div>
        <p class="m-0" v-else>{{ $t("no-title") }}</p>
      </template>

      <!-- Client -->
      <template #cell(client)="{item}">
        {{ item.user ? item.user.name : "None" }}
      </template>

      <!-- Publication date -->
      <template #cell(publication_date)="{item}">{{
        item.publication_date ? formatDate(item.publication_date) : "None"
      }}</template>

      <!-- Notes -->
      <template #cell(notes)="{item}">
        <ebp-note-button :text="truncate(item.notes)" />
      </template>

      <!-- Rejected -->
      <template #cell(rejected)="{ item }">
        {{ item.rejected ? $t("yes") : $t("no") }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <ebp-button
          outlined
          variant="danger"
          size="sm"
          class="ml-1"
          @click="remove(item)"
          :loading="deleting === item.id"
          v-tooltip.top-center="$t('delete')"
        >
          <i class="bx bxs-trash"></i>
        </ebp-button>
      </template>

      <!-- Status -->
      <template #cell(status)="{ item }">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <!-- ISBN -->
      <template #cell(isbn-asin)="{item}">
        <span class="details">
          <a
            class="text-dark"
            target="_blank"
            :href="`https://www.google.com/search?q=${getISBN(item)}`"
            >{{ getISBN(item) }}</a
          >
        </span>
      </template>

      <!-- Custom filters -->
      <template #filters>
        <!-- Status filter -->
        <adt-filter :label="$t('status')">
          <b-select
            :options="addAllOption(statuses)"
            v-model="filters.status"
            size="sm"
          ></b-select>
        </adt-filter>

        <!-- Type filter -->
        <adt-filter :label="$t('type')">
          <b-select
            :options="addAllOption(types)"
            v-model="filters.book_type"
            size="sm"
          ></b-select>
        </adt-filter>

        <!-- Changed filter -->
        <adt-filter :label="$t('changed-only')">
          <b-select
            :options="addAllOption(yesNo, 'all')"
            v-model="filters.is_changed"
            size="sm"
          ></b-select>
        </adt-filter>

        <!-- User filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="filters.user_id"
          />
        </adt-filter>
      </template>

      <!-- Collapsed filters -->
      <template #collapsed-filters>
        <!-- ISBN requested filter -->
        <adt-filter :label="$t('isbn-requested')">
          <b-select
            :options="addAllOption(yesNo, 'all')"
            v-model="filters.isbn_requested"
            size="sm"
          ></b-select>
        </adt-filter>

        <!-- Missing ISBN filter -->
        <adt-filter :label="$t('missing-isbn')">
          <b-select
            :options="addAllOption(yesNo, 'all')"
            v-model="filters.missing_isbn"
            size="sm"
          ></b-select>
        </adt-filter>

        <adt-filter :label="$t('missing-asin')">
          <b-select
            :options="addAllOption(yesNo, 'all')"
            v-model="filters.missing_asin"
            size="sm"
          ></b-select>
        </adt-filter>

        <adt-filter></adt-filter>
        <adt-filter></adt-filter>
        <adt-filter></adt-filter>
      </template>
      <template #bulk-filters>
        <adt-filter class="mt-2">
          <ebp-button size="sm" @click="selectAll">{{
            $t(select_all)
          }}</ebp-button>
        </adt-filter>
        <adt-filter v-if="selectedItems.length">
          <ebp-button size="sm" @click="acknowledgeBooksInBulk">{{
            $t("acknowledge-changes")
          }}</ebp-button>
        </adt-filter>
        <adt-filter :label="$t('update-statuses')" v-if="selectedItems.length">
          <b-select
            :options="statuses"
            v-model="status"
            size="sm"
            @change="updateBulkStatus"
          ></b-select>
        </adt-filter>
      </template>
    </advanced-data-table>

    <!-- Book edit modal -->
    <ebp-modal
      :open.sync="open"
      :width="isSmallScreen ? 'auto' : '40%'"
      :tabs="editTabs"
    >
      <template #error><error :err.sync="error"/></template>
      <template #quick-edit>
        <form-generator
          v-if="editedItem"
          :elements="elements"
          :data="editedItem"
          :handleUpdate="({ key }, v) => (editedItem[key] = v)"
        />
      </template>

      <!-- Changes history -->
      <template #change-history>
        <changes-history
          :id="editedItem.id"
          :book="editedItem"
          v-if="editedItem && renderChanges"
          @acknowledged="acknowledged($event)"
        />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import FormGenerator from "../../../components/form-generator.vue";
import bookSchema from "@/validation-schemas/saveBook";
import BookStatuses from "@/fixtures/book-statuses";
import BookTypes from "@/fixtures/book-types";
import truncate from "@/helpers/truncate";
import ItemSearch from "@/components/core/item-search";
import AdtFilter from "@/components/core/adt-filter";
import bookHelper from "@/helpers/book";
import EbpNoteButton from "../../../components/ebp-note-button";

const ChangesHistory = () => import("@/views/admin/books/changes-history");

export default {
  name: "admin-books",
  props: {
    books: Array,
    options: Object
  },
  components: {
    AdvancedDataTable,
    EbpNoteButton,
    FormGenerator,
    ItemSearch,
    AdtFilter,
    ChangesHistory
  },
  data() {
    return {
      users: [],
      status: null,
      selectedItems: [],
      select_all: "select-all",
      renderChanges: true,
      headers: [
        "select",
        {
          key: "title",
          label: "name",
          sortable: true
        },
        "client",
        "status",
        {
          key: "book_type",
          label: "book-type"
        },
        "isbn-asin",
        "publication_date",
        "notes",
        "rejected"
      ],
      editTabs: ["quick-edit", "change-history"],
      editedItem: null,
      saving: false,
      open: false,
      error: null,
      deleting: false,
      filters: {
        status: null,
        book_type: null,
        publication_date: null,
        rejected: null,
        is_adult_only: null,
        user_id: null,
        is_changed: null,
        missing_isbn: null,
        missing_asin: null,
        isbn_requested: null
      },
      statuses: BookStatuses.map(status => ({
        text: this.$t(status),
        value: status
      })),
      types: BookTypes.map(type => ({
        text: this.$t(type),
        value: type
      }))
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "fxl",
          label: "fxl",
          type: "switch"
        },
        {
          key: "rejected",
          label: "rejected",
          type: "switch"
        },
        {
          key: "commission_rate",
          label: "commission-rate",
          type: "number"
        },
        {
          key: "asin",
          label: "asin",
          type: "string"
        },
        {
          key: "status",
          label: "status",
          type: "select",
          attrs: {
            options: this.statuses
          }
        },
        {
          key: "user",
          label: "client",
          type: "multiselect",
          placeholder: "Enter some text to search for Users",
          attrs: {
            loading: this.loading,
            multiple: false,
            options: (() => {
              let users = this.users;
              if (this.editedItem.user) {
                users = [this.editedItem.user || {}].concat(this.users);
              }

              return users;
            })(),
            trackBy: "id",
            label: "name"
          },
          events: {
            "search-change": this.search
          }
        },
        {
          key: "files_received",
          label: "Files Received",
          type: "switch"
        },
        {
          type: "button-group",
          className: "float-right",
          elements: [
            {
              text: "save",
              type: "button",
              className: "px-5 float-right mt-3",
              events: {
                click: this.save
              }
            },
            {
              text: "complete-edit",
              type: "button",
              className: "px-5 mt-3 btn-dark",
              events: {
                click: () => {
                  const id = this.editedItem.id;
                  this.open = false;
                  this.$nextTick(() => {
                    this.$router.push({
                      name: "AdminEditBook",
                      params: { id }
                    });
                  });
                }
              }
            }
          ]
        }
      ];
    }
  },
  methods: {
    async search(q) {
      if (q) {
        this.loading = true;
        try {
          const res = await this.$store.dispatch("users/search", {
            search: q
          });
          this.users = res.data.data;
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    },
    getISBN: bookHelper.getISBN,
    isLive(book) {
      return book.status === "live";
    },
    acknowledged(bookId) {
      this.editedItem.is_changed = false;
      this.editedItem.last_changes_published_at = this.getISO();
      this.$refs.table.change("id", bookId, {
        ...this.editedItem,
        is_changed: false,
        last_changes_published_at: this.getISO()
      });
      this.renderChanges = false;
      this.$nextTick(() => (this.renderChanges = true));
    },
    truncate,
    async remove(book) {
      this.deleting = book.id;

      if (confirm(this.$t("remove-book", { title: book.title }))) {
        try {
          await this.$store.dispatch("books/delete", book.id);
          this.$refs.table.change("id", book.id);
        } catch (err) {
          console.error(err);
        }
        this.deleting = false;
      } else {
        this.deleting = false;
      }
    },
    selectAll() {
      const ids = this.$refs.table.tableData.map(book => book.id);
      this.select_all = "select-all";
      if (this.selectedItems.length > 1) {
        this.selectedItems = [];
      } else {
        this.select_all = "deselect-all";
        this.selectedItems = ids;
      }
    },
    async acknowledgeBooksInBulk() {
      try {
        const data = {
          ids: this.selectedItems
        };

        await this.$store.dispatch("books/acknowledgeBooksInBulk", data);
        this.$refs.table.get();
        this.select_all = "select-all";

        this.selectedItems = [];
        this.status = null;
      } catch (err) {
        console.error(err);
      }
    },
    async updateBulkStatus() {
      try {
        const data = {
          ids: this.selectedItems,
          status: this.status
        };

        await this.$store.dispatch("books/updateBulkStatuses", data);
        this.$refs.table.get();
        this.select_all = "select-all";

        this.selectedItems = [];
        this.status = null;
      } catch (err) {
        console.error(err);
      }
    },
    openChanges(item) {
      this.editedItem = item;
      this.open = "change-history";
    },
    edit(book) {
      this.editedItem = book;
      this.open = true;
    },
    async save() {
      this.error = null;
      const book = this.editedItem;
      book.user_id = book.user.id;
      const validation = bookSchema.validate(book, this.joiOptions);

      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.saving = true;
        try {
          await this.$store.dispatch("books/update", book);
          this.open = false;
        } catch (err) {
          this.error = this.errorRes(err);
        }
        this.saving = false;
      }
    },
    async changePassword() {
      this.error = null;
      const { new_password, confirm_password } = this.passwords;

      if (!new_password || !confirm_password) {
        this.error = "validation-errors.required-all";
      } else if (new_password !== confirm_password) {
        this.error = "validation-errors.passwords-dont-match";
      } else {
        try {
          await this.$store.dispatch("books/changePassword", {
            id: this.editedItem.id,
            new_password
          });
        } catch (err) {
          this.error = this.errorRes(err);
        }
      }
    }
  },
  watch: {
    open(open) {
      if (!open) {
        this.editedItem = null;
      }
    }
  }
};
</script>

<style lang="scss">
.admin-books {
  .title {
    display: flex;
    align-items: center;

    .bx {
      color: $danger;
      margin-left: 0.25rem;
      font-size: 1rem;
      cursor: pointer;
    }
  }
}
</style>
