<template>
  <div class="form-generator">
    <div class="form-group row">
      <template v-for="(el, i) in elements">
        <template v-if="initiated && show(el)">
          <div
            :class="
              `col-form-label col-lg-3 mt-3 mt-md-0 mb-md-3 ${
                el.required ? 'required' : ''
              }`
            "
            :key="`label-${i}`"
            v-if="el.label && !el.hideLabel"
          >
            <label>{{ $t(el.label) }}</label>
            <span :key="`label-${i}`" v-if="el.helpText" class="label-helper"
              ><i
                class="bx bx-info-circle"
                v-tooltip.top-center="el.helpText"
              ></i
            ></span>
          </div>
          <div :class="col(el)" :key="`col-${i}`">
            <component
              v-if="render"
              :is="
                typeof formEl(el.type) === 'object'
                  ? formEl(el.type).component
                  : formEl(el.type)
              "
              :placeholder="$t(placeholder(el))"
              @input="handleUpdate(el, $event)"
              :value="value(el)"
              :checked="checked(el)"
              v-bind="attrs(el)"
              v-on="events(el)"
              variant="primary"
              tag-variant="primary"
              :class="classes(el)"
            >
              <template v-if="el.text || el.html">
                <template v-if="!el.html">{{ $t(el.text) }}</template>
                <span v-else v-html="$t(el.html)" />
              </template>
              <template v-if="el.elements">
                <template v-for="chEl in el.elements">
                  <component
                    :is="
                      typeof formEl(chEl.type) === 'object'
                        ? formEl(chEl.type).component
                        : formEl(chEl.type)
                    "
                    :placeholder="$t(placeholder(chEl))"
                    @input="handleUpdate(chEl, $event)"
                    :value="value(chEl)"
                    :checked="checked(chEl)"
                    v-bind="attrs(chEl)"
                    v-on="events(chEl)"
                    variant="primary"
                    tag-variant="primary"
                    :key="chEl.key"
                    :class="classes(el, chEl)"
                    v-if="show(chEl)"
                  >
                    {{ $t(chEl.text) }}
                  </component>
                </template>
              </template>
            </component>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
const MultiSelect = () =>
  import(/* webpackChunkName: "multiselect" */ "./core/ebp-multiselect");
const Divider = () =>
  import(/* webpackChunkName: "divider" */ "./core/ebp-divider");
const TreeSelect = () =>
  import(/* webpackChunkName: "treeselect" */ "./core/tree-select");
const EBPDropzone = () =>
  import(/* webpackChunkName: "dropzone" */ "./core/dropzone");
const FlexButtonGroup = () =>
  import(
    /* webpackChunkName: "flex-button-group" */ "./core/flex-button-group"
  );
const DatePicker = () =>
  import(/* webpackChunkName: "datepicker" */ "vue2-datepicker");
const LabeledSwitch = () =>
  import(/* webpackChunkName: "labeledswitch" */ "@/components/labeled-switch");

import { isFunction } from "lodash-es";

export default {
  name: "form-generator",
  props: {
    elements: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    handleUpdate: {
      type: Function,
      required: true
    },
    inline: Boolean,
    initiated: {
      type: Boolean,
      default: () => true
    },
    shouldCheckValidity: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MultiSelect,
    TreeSelect,
    DatePicker,
    LabeledSwitch,
    FlexButtonGroup,
    dropzone: EBPDropzone,
    Divider
  },
  data() {
    return {
      render: true
    };
  },
  methods: {
    shouldValidate(el) {
      return el.validation && this.$props.shouldCheckValidity;
    },
    events(el) {
      const formEl = this.formEl(el.type);
      const customEvents = typeof formEl === "object" ? formEl.events : {};
      return {
        ...this.$listeners,
        ...customEvents,
        ...el.events
      };
    },
    value(el) {
      const attrs = this.attrs(el);
      if (attrs.dontBind) {
        return undefined;
      }
      return this.$props.data[el.key];
    },
    checked(el) {
      const attrs = this.attrs(el);

      if (attrs.checked) {
        return attrs.checked;
      }

      if (attrs.dontBind) {
        return !!this.$props.data[el.key];
      }
      return undefined;
    },
    classes(el, chEl = null) {
      let classes = [];

      // Red border if validation failed

      if (
        this.$refs[`validation-${el.key}`] &&
        this.$refs[`validation-${el.key}`][0] &&
        !this.$refs[`validation-${el.key}`][0].valid &&
        this.$props.shouldCheckValidity
      ) {
        classes.push("is-invalid");
      }

      classes = classes.concat([
        chEl?.className,
        el.childrenClassName,
        el.className,
        el.colClass
      ]);
      return classes.join(" ").trim();
    },
    childrenCol(el) {
      return `col-lg-${12 / el.elements.length}`;
    },
    col(el) {
      if (el.small) {
        return `col-lg-${el.label ? "3" : "6"}`;
      }
      return `col-lg-${el.label ? "9" : "12"}`;
    },
    placeholder(el) {
      if (el.placeholder) {
        return el.placeholder;
      }
      return `${
        ["multiselect", "select"].includes(el.type) ? "select" : "enter"
      }-${el.label || el.placeholder}`;
    },
    show(el) {
      let result = true;

      if (el.showIf) {
        result =
          el.showIf.filter(cond => {
            if (Array.isArray(cond)) {
              return this.data[cond[0]] === cond[1];
            } else if (typeof cond === "string") {
              return !!this.data[cond];
            } else if (isFunction(cond)) {
              return cond();
            }
          }).length === el.showIf.length;
      }

      if (el.hideIf) {
        result = result && !this.data[el.hideIf];
      }

      return result;
    },
    attrs(el) {
      const formEl = this.formEl(el.type);
      const customAttrs = typeof formEl === "object" ? formEl.attrs : {};
      return {
        ...customAttrs,
        ...(this.isSmallScreen ? el.mobileAttrs : el.dekstopAttrs),
        ...el.attrs
      };
    },
    formEl(type) {
      switch (type) {
        case "button-group":
          return "flex-button-group";
        case "string":
          return "b-form-input";
        case "number":
          return { component: "b-form-input", attrs: { type: "number" } };
        case "password":
          return { component: "b-form-input", attrs: { type: "password" } };
        case "text":
          return "b-textarea";
        case "html":
          return {
            component: "vue-editor"
          };
        case "select":
          return "b-form-select";
        case "multiselect":
          return { component: "multi-select", attrs: { multiple: true } };
        case "tree-select":
          return { component: "tree-select", attrs: { multiple: true } };
        case "tags":
          return "b-form-tags";
        case "date":
          return {
            component: "date-picker",
            attrs: { valueType: "YYYY-MM-DD", format: "DD-MMM-YYYY" }
          };
        case "autocomplete":
          return "b-form-datalist";
        case "inline-form":
          return "b-form";
        case "button":
          return "ebp-button";
        case "paragraph":
          return "p";
        case "checkbox":
          return {
            component: "b-form-checkbox",
            attrs: {
              "unchecked-value": false,
              value: true,
              dontBind: true
            }
          };
        case "radio":
          return {
            component: "b-form-radio-group",
            attrs: {
              value: true,
              checked: true,
              stacked: true
            }
          };
        case "switch":
          return {
            component: "labeled-switch",
            attrs: {
              switch: true,
              dontBind: true
            }
          };
        case "hint":
          return "b-form-text";
        case "email":
          return {
            component: "b-input",
            attrs: {
              type: "email"
            }
          };
        default:
          return type;
      }
    }
  }
};
</script>

<style lang="scss">
.form-generator {
  .multiselect__tag {
    margin: 0 !important;
  }
}
</style>
