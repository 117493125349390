var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-generator"},[_c('div',{staticClass:"form-group row"},[_vm._l((_vm.elements),function(el,i){return [(_vm.initiated && _vm.show(el))?[(el.label && !el.hideLabel)?_c('div',{key:`label-${i}`,class:`col-form-label col-lg-3 mt-3 mt-md-0 mb-md-3 ${
              el.required ? 'required' : ''
            }`},[_c('label',[_vm._v(_vm._s(_vm.$t(el.label)))]),(el.helpText)?_c('span',{key:`label-${i}`,staticClass:"label-helper"},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(el.helpText),expression:"el.helpText",modifiers:{"top-center":true}}],staticClass:"bx bx-info-circle"})]):_vm._e()]):_vm._e(),_c('div',{key:`col-${i}`,class:_vm.col(el)},[(_vm.render)?_c(typeof _vm.formEl(el.type) === 'object'
                ? _vm.formEl(el.type).component
                : _vm.formEl(el.type),_vm._g(_vm._b({tag:"component",class:_vm.classes(el),attrs:{"placeholder":_vm.$t(_vm.placeholder(el)),"value":_vm.value(el),"checked":_vm.checked(el),"variant":"primary","tag-variant":"primary"},on:{"input":function($event){return _vm.handleUpdate(el, $event)}}},'component',_vm.attrs(el),false),_vm.events(el)),[(el.text || el.html)?[(!el.html)?[_vm._v(_vm._s(_vm.$t(el.text)))]:_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(el.html))}})]:_vm._e(),(el.elements)?[_vm._l((el.elements),function(chEl){return [(_vm.show(chEl))?_c(typeof _vm.formEl(chEl.type) === 'object'
                      ? _vm.formEl(chEl.type).component
                      : _vm.formEl(chEl.type),_vm._g(_vm._b({key:chEl.key,tag:"component",class:_vm.classes(el, chEl),attrs:{"placeholder":_vm.$t(_vm.placeholder(chEl)),"value":_vm.value(chEl),"checked":_vm.checked(chEl),"variant":"primary","tag-variant":"primary"},on:{"input":function($event){return _vm.handleUpdate(chEl, $event)}}},'component',_vm.attrs(chEl),false),_vm.events(chEl)),[_vm._v(" "+_vm._s(_vm.$t(chEl.text))+" ")]):_vm._e()]})]:_vm._e()],2):_vm._e()],1)]:_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }